import React from "react";
import "./base.scss";
import Container from "./container";

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return <Container>{children}</Container>;
  }
}

export default Layout;
