import React from "react";
import { Link } from "gatsby";

export default ({ children }) => (
  <>
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img
            src="https://media-exp1.licdn.com/dms/image/C4D0BAQGyQ9fBySIh_w/company-logo_200_200/0?e=1596672000&v=beta&t=1fabhVDZPp4knu-Ty6u7UXyHClo8OUf7pUsuTPfUmcw"
            width="52"
            height="52"
          />
        </a>

        <label
          role="button"
          className="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          htmlFor="nav-toggle-state"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </label>
      </div>
      <input type="checkbox" id="nav-toggle-state" />

      <div className="navbar-menu">
        <div className="navbar-start">
          <Link className="navbar-item" to="/events">
            Events
          </Link>

          <Link className="navbar-item" to="/speakers">
            Speakers
          </Link>

          <Link className="navbar-item" to="/venues">
            Venues
          </Link>

          <Link className="navbar-item" to="/about">
            About
          </Link>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <a className="button is-black" href="http://slack.uiux.berlin" target="_blank">
                <strong>Join Community</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div className="container">{children}</div>
  </>
);
