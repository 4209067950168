import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const About = (props) => {
  const organizers = props.data.allContentfulOrganizerProfile.edges;

  return (
    <Layout location={props.location}>
      <div className="columns" style={{ marginTop: "4rem" }}>
        <div className="column is-full" style={{ marginBottom: "2rem" }}>
          <h1 className="title is-1">About uiux.berlin</h1>
        </div>
      </div>

      <div className="columns" style={{ marginBottom: "4rem" }}>
        <div className="column is-half">
          <img src={"https://secure.meetupstatic.com/photos/event/1/8/b/2/600_463026322.jpeg"} alt="uiux.berlin meetup" />
        </div>
        <div className="column is-half">
          <h2 className="subtitle is-3">
            Love wireframing? Daydream of perfecting pixels? Join the Berlin UI/UX Designers Meetup. We meet every month to share our love
            for all things UI/UX design. At each talk/networking event, design leaders in Berlin speak on design topics - more details
            coming soon.
            <br />
            We’re always looking for interesting and engaging designers (or design enthusiasts) to grow the Berlin UI/UX design community,
            if you are interested in speaking at a Berlin UI/UX Meetup email <a href="mailto:info@uiux.berlin">info@uiux.berlin</a>.
          </h2>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <h2 className="title is-2">The Organizer</h2>
        </div>
      </div>

      {organizers.map((data) => (
        <Organizer key={data.node.id} name={data.node.name} text={data.node.text.text} photo={data.node.photo.resize.src} />
      ))}
    </Layout>
  );
};

const Organizer = ({ name, text, photo }) => {
  return (
    <div className="columns" style={{ marginBottom: "3rem" }}>
      <div className="column is-2">
        <figure className="image is-96x96">
          <img className="is-rounded" src={photo} alt={name} />
        </figure>
      </div>
      <div className="column is-10">
        <h2 className="subtitle is-3">{name}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query {
    allContentfulOrganizerProfile(sort: { fields: name }) {
      edges {
        node {
          id
          name
          text {
            text
          }
          photo {
            description
            resize(width: 128, height: 128) {
              src
            }
          }
        }
      }
    }
  }
`;

export default About;
